<template>
    <div>
        <van-empty image="error" description="页面不存在" />
        <div class="add-big-button">
            <van-button class="button" size="small" round @click="submit">返回主页</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "abnormal",
        methods: {
            submit() {
                this.$router.replace('/')
            }
        }
    }
</script>

<style scoped>
    .add-big-button {
        display: flex;
        justify-content: center;
        width: calc(100% - 30px);
        margin: 44px 15px;
    }

    .add-big-button button {
        padding: 0 15px;
    }

    .button {
        background: var(--main-color);
        color: #fff;
    }
</style>